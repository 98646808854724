// tslint:disable
/**
 * FleetOnRoute API
 * <p>This API powers <a href='https://fleetonroute.com'>https://fleetonroute.com</a>.</p>    <p>To begin, authenticate using the <a href='#/auth/login'>Login</a> method below with your credentials. </p>    <p>To create an account, please log on to <a href='https://portal.fleetonroute.com'>https://portal.fleetonroute.com</a></p>    <p><a href=\"/public/oss-licenses.txt\">Open Source Attributions</a></p>
 *
 * OpenAPI spec version: 1.22.0
 * Contact: support@fleetonroute.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ConfigurationParameters {
    apiKey?: string | ((name: string) => string);
    username?: string;
    password?: string;
    accessToken?: string | ((name: string, scopes?: string[]) => string);
    basePath?: string;
}

export class Configuration {
    /**
     * parameter for apiKey security
     * @param name security name
     * @memberof Configuration
     */
    apiKey?: string | ((name: string) => string);
    /**
     * parameter for basic security
     * 
     * @type {string}
     * @memberof Configuration
     */
    username?: string;
    /**
     * parameter for basic security
     * 
     * @type {string}
     * @memberof Configuration
     */
    password?: string;
    /**
     * parameter for oauth2 security
     * @param name security name
     * @param scopes oauth2 scope
     * @memberof Configuration
     */
    accessToken?: string | ((name: string, scopes?: string[]) => string);
    /**
     * override base path
     * 
     * @type {string}
     * @memberof Configuration
     */
    basePath?: string;

    constructor(param: ConfigurationParameters = {}) {
        this.apiKey = param.apiKey;
        this.username = param.username;
        this.password = param.password;
        this.accessToken = param.accessToken;
        this.basePath = param.basePath;
    }
}
